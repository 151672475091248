import { Controller } from '@hotwired/stimulus';
import { getTransitionEndEvent } from '../utils/transition-end-event';

export default class extends Controller {
  static targets = ['modal', 'overlay', 'panel'];

  initialize () {
    this.panelEnterClasses = ['opacity-100', 'translate-y-0', 'sm:scale-100'];
    this.panelLeaveClasses = ['opacity-0', 'translate-y-4', 'sm:translate-y-0', 'sm:scale-95'];
    this.overlayEnterClass = 'opacity-100';
    this.overlayLeaveClass = 'opacity-0';
    // Need to use visibility classes instead of display none so
    // firefox will calculate widths of embedded iframes correctly
    this.modalEnterClass = 'visible';
    this.modalLeaveClass = 'invisible';
    this.transitionEnd = getTransitionEndEvent();
    this.isShown = false;
  }

  connect () {
    if (!this.hasPanelTarget) {
      return;
    }

    this.panelTarget.addEventListener(this.transitionEnd, () => { this.handleTransitionEnd(); });
  }

  disconnect () {
    if (!this.hasPanelTarget) {
      return;
    }

    this.panelTarget.removeEventListener(this.transitionEnd, () => { this.handleTransitionEnd(); });
  }

  handleTransitionEnd () {
    if (!this.isShown) {
      this.toggleContainerVisibility(this.isShown);
    }
  }

  handleKeyDown (event) {
    if (event.key !== 'Escape' || !this.isShown) {
      return;
    }

    this.hide();
  }

  show () {
    this.isShown = true;
    this.toggleContainerVisibility(this.isShown);

    // Add it to the stack so the panel transition happens
    // after the container is shown
    setTimeout(() => {
      this.togglePanelVisibility(this.isShown);
    });
  }

  hide () {
    this.isShown = false;
    this.togglePanelVisibility(this.isShown);
  }

  togglePanelVisibility (shouldShow) {
    if (!this.hasOverlayTarget || !this.hasPanelTarget) {
      return;
    }

    let panelAdd = this.panelLeaveClasses;
    let panelRemove = this.panelEnterClasses;
    let overlayAdd = this.overlayLeaveClass;
    let overlayRemove = this.overlayEnterClass;
    if (shouldShow) {
      panelAdd = this.panelEnterClasses;
      panelRemove = this.panelLeaveClasses;
      overlayAdd = this.overlayEnterClass;
      overlayRemove = this.overlayLeaveClass;
    }

    this.overlayTarget.classList.replace(overlayRemove, overlayAdd);
    this.panelTarget.classList.add(...panelAdd);
    this.panelTarget.classList.remove(...panelRemove);
  }

  toggleContainerVisibility (shouldShow) {
    if (!this.hasModalTarget) {
      return;
    }

    let modalAdd = this.modalLeaveClass;
    let modalRemove = this.modalEnterClass;
    let ariaRemove = 'aria-modal';
    let ariaAdd = 'aria-hidden';
    if (shouldShow) {
      modalAdd = this.modalEnterClass;
      modalRemove = this.modalLeaveClass;
      ariaRemove = 'aria-hidden';
      ariaAdd = 'aria-modal';
    }

    this.modalTarget.classList.replace(modalRemove, modalAdd);
    this.modalTarget.removeAttribute(ariaRemove);
    this.modalTarget.setAttribute(ariaAdd, 'true');
  }
}
