import { Controller } from '@hotwired/stimulus';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js';

export default class extends Controller {
  initialize () {
    // browser supports scroll-behavior css property, exit early
    if (typeof window.getComputedStyle(document.body).scrollBehavior !== 'undefined') {
      return;
    }

    gsap.registerPlugin(ScrollToPlugin);

    // If a link's href is within the current page, scroll to it instead
    document.querySelectorAll('a[href]').forEach(a => {
      a.addEventListener('click', e => {
        this.scrollToHash(this.getSamePageAnchor(a), e);
      });
    });

    // Scroll to the element in the URL's hash on load
    this.scrollToHash(window.location.hash);
  }

  /**
   * Detect if a link's href goes to the current page
   * @param {String} link
   * @returns {Boolean|String}
   */
  getSamePageAnchor (link) {
    if (
      link.protocol !== window.location.protocol ||
      link.host !== window.location.host ||
      link.pathname !== window.location.pathname ||
      link.search !== window.location.search
    ) {
      return false;
    }

    return link.hash;
  }

  /**
   * Scroll to a given hash, preventing the event given if there is one
   * @param {String} hash - page fragment hash
   * @param {Object} e - browser event object
   */
  scrollToHash (hash, e) {
    const elem = hash ? document.querySelector(hash) : false;
    if (elem) {
      if (e) {
        e.preventDefault();
      }

      gsap.to(window, {
        scrollTo: {
          y: elem,
          offsetY: 64
        }
      });
    }
  }
}
