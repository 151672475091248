import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger', 'triggerIcon', 'menu'];
  static classes = ['rotate'];
  static values = {
    open: Boolean,
    openClasses: Array,
    closedClasses: Array
  };

  /**
   * Menu open/close value watcher
   */
  openValueChanged () {
    if (this.openValue) {
      this.open();
    } else {
      this.close();
    }
  }

  /**
   * Toggle the open/close value
   */
  toggle () {
    this.openValue = !this.openValue;
  }

  /**
   * Handles when the user clicks away from the menu
   * @param {Object} [event] - browser's event object
   */
  away (event) {
    if (this.element.contains(event.target) === false && this.openValue) {
      this.toggle();
    }
  }

  /**
   * Handles when the user hits the escape key
   * @param {Object} [event] - browser's event object
   */
  escape (event) {
    const key = event.key || event.which;
    if ((key === 'Escape' || key === 'Esc' || key === 27) && this.openValue) {
      this.toggle();
    }
  }

  /**
   * Shows the dropdown menu
   */
  open () {
    this.triggerTarget.setAttribute('aria-expanded', true);
    this.menuTarget.classList.add(this.openClass);

    this.menuTarget.classList.add(...this.openClassesValue);
    this.menuTarget.classList.remove(...this.closedClassesValue);

    if (this.hasTriggerIconTarget) {
      this.triggerIconTarget.classList.add(this.rotateClass);
    }
  }

  /**
   * Hides the dropdown menu
   */
  close () {
    this.triggerTarget.setAttribute('aria-expanded', false);
    this.menuTarget.classList.add(...this.closedClassesValue);
    this.menuTarget.classList.remove(...this.openClassesValue);
    if (this.hasTriggerIconTarget) {
      this.triggerIconTarget.classList.remove(this.rotateClass);
    }
  }
}
