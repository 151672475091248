import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static classes = ['visibility', 'inactive'];
  static targets = [
    'sliderOutput',
    'plan',
    'roiNumber',
    'savingsNumber',
    'separator'
  ];

  static values = {
    plan: String,
    spend: Number,
    savingsRate: Number
  };

  MIN_SPEND = 1;
  MAX_SPEND = 50;
  MIN_PLATINUM_SPEND = 7;
  MAX_PLATINUM_SPEND = 25;

  // yearly pricing of traxo plans
  PRICING = {
    gold: 30000,
    platinum: 60000,
    enterprise: 120000
  };

  // percentage savings rate based on program structure
  SAVINGS_RATES = {
    managed: 3,
    hybrid: 4.4,
    unmanaged: 9.6
  };

  planValueChanged () {
    this.displayPlanAndSetCost(this.planValue);
  }

  spendValueChanged () {
    this.setSpendLabel(this.spendValue);
    this.setRecommendedPlan(this.spendValue);

    if (this.planCost) {
      this.calculateSavingsAndRoi(this.spendValue, this.planCost);
    }
  }

  savingsRateValueChanged () {
    if (this.spendValue && this.planCost) {
      this.calculateSavingsAndRoi(this.spendValue, this.planCost);
    }
  }

  /**
   * Handler triggered by the spend range slider action
   * Sets the currently selected spend value
   * @param {Object} event
   */
  setSpend (event) {
    this.spendValue = event.currentTarget.valueAsNumber;
  }

  /**
   * Handler triggered by the program selector action
   * Sets the currently selected savings rate
   * @param {Object} event
   */
  setSavingsRate (event) {
    this.savingsRateValue = this.SAVINGS_RATES[event.currentTarget.value.toLowerCase()];
  }

  /**
   * Finds DOM element of matching plan and grabs the
   * cost data and sets it the controller value
   * @param {String} planName
   */
  displayPlanAndSetCost (planName) {
    if (!this.hasPlanTarget) {
      return;
    }

    this.planTargets.forEach(plan => {
      let shouldHide = true;
      if (plan.dataset.planName === planName) {
        shouldHide = false;
        this.planCost = this.PRICING[planName];
      }

      if (this.hasVisibilityClass) {
        plan.classList.toggle(this.visibilityClass, shouldHide);
      }
    });
  }

  /**
   * Set the recommended plan based on the selected
   * spend amount
   * @param {Number} spend
   */
  setRecommendedPlan (spend) {
    if (spend < this.MIN_PLATINUM_SPEND) {
      this.planValue = 'gold';
    } else if (spend >= this.MIN_PLATINUM_SPEND && spend <= this.MAX_PLATINUM_SPEND) {
      this.planValue = 'platinum';
    } else {
      this.planValue = 'enterprise';
    }
  }

  /**
   * Updates the spend slider label in the DOM
   * @param {Number} spend
   */
  setSpendLabel (spend) {
    let label = `$${spend} million`;

    if (spend === this.MIN_SPEND) {
      label = `< $${spend} million`;
    } else if (spend === this.MAX_SPEND) {
      label = `$${spend} million +`;
    }

    if (this.hasSliderOutputTarget) {
      this.sliderOutputTarget.textContent = label;
    }
  }

  calculateSavingsAndRoi (spend, planCost) {
    // pull the savings rate from the markup or use the middle tier default
    let rate = this.hasSavingsRateValue ? this.savingsRateValue : 4.4;

    // convert to a percent decimal
    rate = rate / 100;

    // Convert raw spend amount from slider to millions
    // and calculate the savings rate dollar amount
    let savings = (spend * 1000000) * rate;

    // round that savings amount to the nearest thousand
    savings = Math.round(savings / 1000) * 1000;

    // savings / yearly traxo plan = return
    const roi = Math.round(savings / planCost);

    this.displaySavings(savings);
    this.displayRoi(roi);
  }

  displaySavings (savings) {
    // reverse numbers so we can fill boxes from right to left
    const numbers = Array.from(String(savings)).reverse();

    if (this.hasSeparatorTarget) {
      this.separatorTargets.forEach((separator, index) => {
        this.updateSeparatorStatus(separator, index, numbers);
      });
    }

    if (this.hasSavingsNumberTarget) {
      this.updateNumberBoxes(this.savingsNumberTargets, numbers);
    }
  }

  displayRoi (roi) {
    // reverse numbers so we can fill boxes from right to left
    const numbers = Array.from(String(roi)).reverse();
    if (this.hasRoiNumberTarget) {
      this.updateNumberBoxes(this.roiNumberTargets, numbers);
    }
  }

  updateNumberBoxes (boxes, numbers) {
    let index = 0;
    for (let i = boxes.length - 1; i >= 0; i -= 1) {
      const isActive = (index <= numbers.length - 1);
      const thisNumber = isActive ? numbers[index] : '0';

      boxes[i].textContent = thisNumber;
      if (this.hasInactiveClass) {
        boxes[i].classList.toggle(this.inactiveClass, !isActive);
      }

      index += 1;
    }
  }

  updateSeparatorStatus (separator, index, numbers) {
    let isActive = false;
    const numberLength = numbers.length;

    // activate both separators OR Just the first one
    if (numberLength >= 7 || (numberLength >= 4 && index !== 0)) {
      isActive = true;
    }

    if (this.hasInactiveClass) {
      separator.classList.toggle(this.inactiveClass, !isActive);
    }
  }
}
