import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field'];
  static classes = ['errorMessage', 'errorField', 'validField'];

  initialize () {
    this.form = this.element;
  }

  connect () {
    this.form.setAttribute('novalidate', '');
  }

  validate (event) {
    const field = event.currentTarget;
    const error = this.hasError(field);
    if (error) {
      this.showError(field, error);
      return;
    }

    // remove existing error message
    this.removeError(field);
  }

  validateAll (event) {
    let firstErrorField;

    // show all the errors and keep track of the first
    // field with an error;
    this.fieldTargets.forEach(field => {
      const error = this.hasError(field);
      if (error) {
        this.showError(field, error);
        if (!firstErrorField) {
          firstErrorField = field;
        }
      }
    });

    // If there is at least one error focus the first
    // on and stop the form submission
    if (firstErrorField) {
      event.preventDefault();

      // this stops any action after the validation action
      // in the controller's element data-action attribute
      // namely form submission
      event.stopImmediatePropagation();
      firstErrorField.focus();
    }
  }

  hasError (field) {
    // if its valid just short circuit anything else and return undefined
    const validity = field.validity;
    if (validity.valid) {
      return;
    }

    return this.getErrorMessage(validity, field);
  }

  showError (field, error) {
    const inputGroup = field.closest('.js-input-group');
    if (inputGroup) {
      inputGroup.classList.add(...this.errorFieldClasses);
      inputGroup.classList.remove(...this.validFieldClasses);
    } else {
      field.classList.add(...this.errorFieldClasses);
      field.classList.remove(...this.validFieldClasses);
    }

    const id = this.getFieldIdentifier(field);
    if (!id) {
      return;
    }

    let message = this.form.querySelector(`#error-for-${id}`);
    if (!message) {
      message = document.createElement('p');
      message.classList.add(...this.errorMessageClasses);
      message.id = `error-for-${id}`;

      if (inputGroup) {
        inputGroup.parentNode.insertBefore(message, inputGroup.nextSibling);
      } else {
        field.parentNode.insertBefore(message, field.nextSibling);
      }
    }

    // Add ARIA role to the field
    field.setAttribute('aria-describedby', `error-for-${id}`);

    // Update error message
    message.innerHTML = error;

    // Show error message
    message.style.display = 'block';
    message.style.visibility = 'visible';
  }

  removeError (field) {
    const inputGroup = field.closest('.js-input-group');
    if (inputGroup) {
      inputGroup.classList.remove(...this.errorFieldClasses);
      inputGroup.classList.add(...this.validFieldClasses);
    } else {
      field.classList.remove(...this.errorFieldClasses);
      field.classList.add(...this.validFieldClasses);
    }

    field.removeAttribute('aria-describedby');

    const id = this.getFieldIdentifier(field);
    if (!id) {
      return;
    }

    const message = this.form.querySelector(`#error-for-${id}`);
    if (message) {
      message.innerHTML = '';
      message.style.display = 'none';
      message.style.visibility = 'hidden';
    }
  }

  getFieldIdentifier (field) {
    return field.id || field.name;
  }

  getErrorMessage (validity, field) {
    let msg;
    // required
    if (validity.valueMissing) {
      msg = 'Please fill out this field.';

    // type="" attribute
    } else if (validity.typeMismatch) {
      if (field.type === 'email') {
        msg = 'Please enter an email address.';
      } else if (field.type === 'url') {
        msg = 'Please enter a URL.';
      } else {
        msg = 'Please use the correct input type.';
      }

    // minlength=
    } else if (validity.tooShort) {
      msg = `Please lengthen this text to ${field.getAttribute('minlength')} characters or more. You are currently using ${field.value.length} characters.`;

    // maxlength=
    } else if (validity.tooLong) {
      msg = `Please shorten this text to ${field.getAttribute('maxlength')} characters or more. You are currently using ${field.value.length} characters.`;

    // type='number'
    } else if (validity.badInput) {
      msg = 'Please enter a number.';

    // step=
    } else if (validity.stepMismatch) {
      msg = 'Please select a valid value.';

    // max=
    } else if (validity.rangeOverflow) {
      msg = `Please select a value that is no more than ${field.getAttribute('max')}.`;

    // min=
    } else if (validity.rangeUnderflow) {
      msg = `Please select a value that is no less than ${field.getAttribute('min')}.`;

    // pattern=
    } else if (validity.patternMismatch) {
      if (field.hasAttribute('title')) {
        msg = field.getAttribute('title');
      } else {
        msg = 'Please match the requested format.';
      }
    } else {
      msg = 'The value you entered for this field is invalid.';
    }
    return msg;
  }
}
