import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['menuTrigger', 'iconClosed', 'iconOpen', 'menu'];
  static values = { open: Boolean };

  connect () {
    this.menuTriggerTarget.classList.remove('hidden');
  }

  toggle () {
    this.openValue = !this.openValue;
  }

  /**
   * Menu open/close value watcher
   */
  openValueChanged () {
    if (this.openValue) {
      this.open();
    } else {
      this.close();
    }
  }

  open () {
    // Menu Icon Buttons
    this.iconClosedTarget.classList.replace('inline-flex', 'hidden');
    this.iconOpenTarget.classList.replace('hidden', 'inline-flex');

    // Menu
    this.menuTarget.classList.replace('hidden', 'block');

    // Aria Accessibility Attributes;
    this.menuTriggerTarget.setAttribute('aria-label', 'Close main menu');
    this.menuTriggerTarget.setAttribute('aria-expanded', 'true');
  }

  close () {
    // Menu Icon Buttons
    this.iconClosedTarget.classList.replace('hidden', 'inline-flex');
    this.iconOpenTarget.classList.replace('inline-flex', 'hidden');

    // Menu
    this.menuTarget.classList.replace('block', 'hidden');

    // Aria Accessibility Attributes;
    this.menuTriggerTarget.setAttribute('aria-label', 'Main menu');
    this.menuTriggerTarget.setAttribute('aria-expanded', 'false');
  }
}
