import { Controller } from '@hotwired/stimulus';
import KeenSlider from 'keen-slider';

export default class extends Controller {
  static classes = ['gap'];
  static values = {
    reverse: Boolean,
    // Number in milliseconds it takes for the slide animation to complete
    speed: { type: Number, default: 10000 },
  };

  initialize() {
    this.element.classList.remove(this.gapClass);
  }

  connect() {
    this.slider = new KeenSlider(this.element, {
      loop: true,
      mode: 'free',
      selector: '.slider__slide',
      renderMode: 'precision',
      rtl: this.reverseValue,
      slides: {
        perView: 'auto',
        // Make sure this spacing matches the value of the gap class in the html
        // this will make sure the space is consistent between the logos before
        // this controller loads.
        spacing: 80,
      },
      defaultAnimation: {
        duration: this.speedValue,
        easing: (time) => time,
      },
      created(props) {
        props.moveToIdx(2, true);
      },
      updated(props) {
        props.moveToIdx(props.track.details.abs + 2, true);
      },
      animationEnded(props) {
        props.moveToIdx(props.track.details.abs + 2, true);
      },
    });
  }

  disconnect() {
    this.slider.destroy();
    this.element.classList.add(this.gapClass);
  }
}
