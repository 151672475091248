import { Controller } from '@hotwired/stimulus';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

export default class extends Controller {
  static targets = ['item'];

  initialize () {
    this.scrollTriggers = [];
    gsap.registerPlugin(ScrollTrigger);
  }

  connect () {
    if (!this.hasItemTarget) {
      return;
    }

    this.setup(this.itemTargets);
  }

  disconnect () {
    this.scrollTriggers.forEach(trigger => trigger.kill());
  }

  setup (items = []) {
    items.forEach(item => {
      const ease = this.getText(item, 'data-tween-ease') || 'power1.out';
      const x = this.getAmount(item, 'data-tween-x');
      const y = this.getAmount(item, 'data-tween-y');
      const opacity = this.getAmount(item, 'data-tween-opacity') || 0;
      const duration = this.getAmount(item, 'data-tween-duration') || 0.5;
      const delay = this.getAmount(item, 'data-tween-delay') || 0;

      const anim = gsap.from(item, {
        x: (index, target) => {
          return x !== null ? x : -target.offsetWidth;
        },
        y: (index, target) => {
          return y !== null ? y : -target.offsetHeight;
        },
        opacity,
        duration,
        delay,
        ease
      });

      this.animate(item, anim);
    });
  }

  animate (item, anim) {
    this.scrollTriggers.push(
      ScrollTrigger.create({
        animation: anim,
        trigger: item,
        start: 'top 75%',
        end: 'top 25%',
        scrub: false
      })
    );
  }

  getAmount (target, attr) {
    const amount = Number(target.getAttribute(attr));
    return (target.hasAttribute(attr) && !Number.isNaN(amount)) ? amount : null;
  }

  getText (target, attr) {
    const text = target.hasAttribute(attr) ? target.getAttribute(attr) : null;
    return (target.hasAttribute(attr) && typeof text === 'string') ? text : null;
  }
}
