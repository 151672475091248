import { Controller } from '@hotwired/stimulus';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

export default class extends Controller {
  static targets = ['headerCTA', 'bodyCTA'];
  static classes = ['visible', 'invisible'];

  initialize () {
    gsap.registerPlugin(ScrollTrigger);
  }

  connect () {
    const visibleClass = this.hasVisibleClass ? this.visibleClass : '';
    const invisibleClass = this.hasInvisibleClass ? this.invisibleClass : '';

    if (!this.hasBodyCTATarget) {
      this.headerCTATarget.classList.add(visibleClass);
      this.headerCTATarget.classList.remove(invisibleClass);
      return;
    }

    this.toggleCallToActionHeaderButton(visibleClass, invisibleClass);
  }

  disconnect () {
    if (this.CTAScroller) {
      this.CTAScroller.kill();
    }
  }

  /**
   * Hide header call-to-action (CTA) if the same
   * CTA in the body is showing, show the header CTA
   * once the user scrolls past the body CTA
   */
  toggleCallToActionHeaderButton (visibleClass, invisibleClass) {
    this.CTAScroller = ScrollTrigger.create({
      trigger: this.bodyCTATarget,
      start: 'top top',
      endTrigger: 'body',
      end: 'bottom top',
      onToggle: (self) => {
        // the toggleClass ScrollTrigger.create option only allows toggling a single class but since
        // we use tailwindCSS we need to toggle two different classes
        this.headerCTATarget.classList.toggle(visibleClass, self.isActive);
        this.headerCTATarget.classList.toggle(invisibleClass, !self.isActive);
      },
    });
  }
}
