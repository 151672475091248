import { Controller } from '@hotwired/stimulus';
import tippy from 'tippy.js';

export default class extends Controller {
  static targets = ['trigger', 'content'];
  static classes = ['visibility'];

  connect() {
    if (!this.hasTriggerTarget) {
      return;
    }

    // Show the tooltip trigger button
    if (this.hasVisibilityClass) {
      this.triggerTargets.forEach((trigger) => {
        trigger.classList.remove(this.visibilityClass);
      });

      // Hide then inline program description
      if (this.hasContentTarget) {
        this.contentTargets.forEach((content) => {
          content.classList.add(this.visibilityClass);
        });
      }
    }

    const options = {
      placement: 'top',
      allowHTML: true,
      interactive: true,
      theme: 'txo-blue',
      offset: [0, 8],
    };

    if (this.hasContentTarget) {
      // Find the content block nearby
      options.content = (trigger) => {
        const el = trigger
          .closest('[data-tooltip="container"]')
          .querySelector('[data-tooltip-target="content"]');
        return el.innerHTML;
      };
    }

    this.tooltip = tippy(this.triggerTargets, options);
  }

  disconnect() {
    if (this.tooltip) {
      this.tooltip.destroy();
    }
  }
}
