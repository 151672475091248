import { Controller } from '@hotwired/stimulus';
import DOMPurify from 'dompurify';

export default class extends Controller {
  static classes = ['visible', 'hidden'];
  static targets = [
    'submitGroup',
    'submitButton',
    'successMsg',
    'successMsgContent',
    'errorMsg',
  ];

  SPEND_FIELD_NAME = 'n2019_travel_program_spend';

  connect() {
    this.resource = this.element.getAttribute('action');
  }

  submit(event) {
    event.preventDefault();
    if (!this.resource) {
      return;
    }

    const formData = this.serialize(new FormData(this.element));
    const payload = {
      fields: this.format(formData),
      context: this.getContext(),
    };

    this.send(payload);
  }

  /**
   * Convert the data object into an array of objects
   * that hubspot requires
   * @param {Object} formData - Browser FormData object
   * @returns {Array}
   */
  serialize(formData) {
    const data = [];
    for (const pair of formData.entries()) {
      data.push({
        name: pair[0],
        value: pair[1],
      });
    }

    return data;
  }

  /**
   * Format the field level data in a way hubspot requires
   * @param {Array} formData - serialized form data
   * @returns {Array}
   */
  format(formData) {
    return formData.map((field) => {
      if (field.name === this.SPEND_FIELD_NAME) {
        field.value = this.formatSpendValue(field);
      }
      return field;
    });
  }

  /**
   * The spend slider contains a base number string
   * format it as a readable value to send to hubspot
   * @param {Object} field - formData for an individual field
   * @returns {String}
   */
  formatSpendValue(field) {
    let val = `$${field.value} million`;
    if (field.value === '1') {
      val = `< ${val}`;
    } else if (field.value === '50') {
      val = `${val} +`;
    }
    return val;
  }

  /**
   * Get user and page context
   * @returns {Object}
   */
  getContext() {
    return {
      hutk: this.getHubSpotCookie(),
      pageUri: window.location.href,
      pageName: document.title,
    };
  }

  /**
   * If there is a hubspot cookie grab it to send along
   * @returns {Object|undefined}
   */
  getHubSpotCookie() {
    let hsCookie;

    if (document.cookie) {
      hsCookie = document.cookie.split(';').find(function (row) {
        return row.trim().startsWith('hubspotutk');
      });
    }

    if (hsCookie) {
      hsCookie = hsCookie.split('=')[1];
    }

    return hsCookie;
  }

  /**
   * Sends form to hubspot and handles responses
   * @param {Object} payload
   */
  async send(payload) {
    this.toggleButtonState(true);
    this.toggleErrorMsg('add');

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(this.resource, options);
      if (!response.ok) {
        throw new Error(`An error has occured: ${response.status}`);
      }

      const body = await response.json();
      this.handleSuccess(body);
    } catch (error) {
      this.toggleErrorMsg('remove', error);
    }

    this.toggleButtonState(false);
  }

  clearMessages() {
    this.toggleButtonState(false);
    this.toggleErrorMsg('add');
  }

  handleSuccess(response) {
    let msg =
      response.inlineMessage || 'Received! We will contact you shortly.';
    msg = DOMPurify.sanitize(msg, { USE_PROFILES: { html: true } });

    if (this.hasSubmitGroupTarget && this.hasHiddenClass) {
      this.submitGroupTarget.classList.add(this.hiddenClass);
    }

    if (this.hasSuccessMsgContentTarget) {
      this.successMsgContentTarget.innerHTML = msg;
    }

    if (this.hasSuccessMsgTarget && this.hasHiddenClass) {
      this.successMsgTarget.classList.remove(this.hiddenClass);
    }
  }

  toggleButtonState(disabled = true) {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = disabled;
    }
  }

  toggleErrorMsg(fn = 'remove') {
    if (this.hasErrorMsgTarget && this.hasHiddenClass) {
      this.errorMsgTarget.classList[fn](this.hiddenClass);
    }
  }
}
