import { Controller } from '@hotwired/stimulus';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

export default class extends Controller {
  static classes = ['scrolling'];

  initialize () {
    gsap.registerPlugin(ScrollTrigger);
  }

  connect () {
    this.headerScroll = ScrollTrigger.create({
      trigger: this.element,
      start: 'top top',
      // basically never end
      endTrigger: 'body',
      end: 'bottom top',
      toggleClass: this.hasScrollingClass ? this.scrollingClass : ''
    });
  }

  disconnect () {
    if (this.headerScroll) {
      this.headerScroll.kill();
    }
  }
}
