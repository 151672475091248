import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect () {
    this.setVar('--min', this.element.min);
    this.setVar('--max', this.element.max);
    this.setVar('--val', this.element.value);

    this.element.classList.add('js');
  }

  update (event) {
    this.setVar('--val', event.currentTarget.value);
  }

  setVar (name, value) {
    this.element.style.setProperty(name, value);
  }
}
