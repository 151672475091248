import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'label', 'nextQuestion'];
  static classes = ['active'];

  /**
   * Action for program selection
   * @param {Object} event
   */
  select (event) {
    if (!this.hasInputTarget || !this.hasLabelTarget) {
      return;
    }

    // When an input is selected add the active class to its label
    // And remove it from all others
    if (this.hasActiveClass) {
      this.inputTargets.forEach((input, index) => {
        const label = this.labelTargets[index];
        if (label) {
          const fn = (input === event.currentTarget) ? 'add' : 'remove';
          label.classList[fn](this.activeClass);
        }
      });
    }

    // < 768px = mobile landscape breakpoint
    if (document.body.clientWidth < 768 && this.hasNextQuestionTarget) {
      this.nextQuestionTarget.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
}
