import { Controller } from '@hotwired/stimulus';
import DOMPurify from 'dompurify';

export default class extends Controller {
  static classes = ['visible', 'hidden'];
  static values = { scrollId: String };
  static targets = [
    'fields',
    'focus',
    'submitButton',
    'success',
    'successMessage',
    'error',
  ];

  connect() {
    this.resource = this.element.getAttribute('action');
    this.form = this.element;
  }

  process(event) {
    event.preventDefault();
    if (!this.resource) {
      return;
    }

    const payload = {
      fields: this.serialize(new FormData(this.form)),
      context: this.getContext(),
    };

    this.send(payload);
  }

  /**
   * Convert the data object into an array of objects
   * that hubspot requires
   * @param {Object} formData - Browser FormData object
   * @returns {Array}
   */
  serialize(formData) {
    const data = [];
    for (const pair of formData.entries()) {
      data.push({
        name: pair[0],
        value: pair[1],
      });
    }

    return data;
  }

  /**
   * Get user and page context
   * @returns {Object}
   */
  getContext() {
    return {
      hutk: this.getHubSpotCookie(),
      pageUri: window.location.href,
      pageName: document.title,
    };
  }

  /**
   * If there is a hubspot cookie grab it to send along
   * @returns {Object|undefined}
   */
  getHubSpotCookie() {
    let hsCookie;

    if (document.cookie) {
      hsCookie = document.cookie.split(';').find(function (row) {
        return row.trim().startsWith('hubspotutk');
      });
    }

    if (hsCookie) {
      hsCookie = hsCookie.split('=')[1];
    }

    return hsCookie;
  }

  /**
   * Sends form to hubspot and handles responses
   * @param {Object} payload
   */
  async send(payload) {
    this.toggleButtonState(true);
    this.toggleErrorMsg('add');

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(this.resource, options);
      if (!response.ok) {
        throw new Error(`An error has occured: ${response.status}`);
      }

      const body = await response.json();
      this.handleSuccess(body);
    } catch (error) {
      this.toggleErrorMsg('remove', error);
    }

    this.toggleButtonState(false);
  }

  clearMessages() {
    this.toggleButtonState(false);
    this.toggleErrorMsg('add');
  }

  handleSuccess(response) {
    let msg =
      response.inlineMessage ||
      this.successMessageTarget.textContent ||
      'Received!&nbsp;We will contact you shortly.';
    msg = DOMPurify.sanitize(msg, { USE_PROFILES: { html: true } });

    if (this.hasFieldsTarget) {
      this.fieldsTarget.classList.add(this.hiddenClass);
    }

    if (this.hasSuccessTarget && this.hasSuccessMessageTarget) {
      this.successMessageTarget.innerHTML = msg;
      this.successTarget.classList.remove(this.hiddenClass);
    }

    if (this.hasScrollIdValue) {
      window.location.hash = this.scrollIdValue;
    }
  }

  reset() {
    this.form.reset();
    if (this.hasFieldsTarget) {
      this.fieldsTarget.classList.remove(this.hiddenClass);
    }

    if (this.hasSuccessTarget) {
      this.successTarget.classList.add(this.hiddenClass);
    }

    if (this.hasFocusTarget) {
      this.focusTarget.focus();
    }
  }

  toggleButtonState(disabled = true) {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = disabled;
    }
  }

  toggleErrorMsg(fn = 'remove') {
    if (this.hasErrorTarget && this.hasHiddenClass) {
      this.errorTarget.classList[fn](this.hiddenClass);
    }
  }
}
